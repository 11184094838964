import { React, SEO, styled } from '../shipityo'

import Layout from "../components/layout"
import PageHeading from "../components/pageheading"

const MenuStyles = styled.div`
  text-align: center;
  margin-bottom: 70px;
  h2{
    margin-top: 50px;
  }
  ul{
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
  li{
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
  }
`;

let starters = [
  "Vegtable Samoosa",
  "Chicken Samoosa",
  "Lamb Samoosa",
  "Bhajia (Chilli Bites)",
  "Paneer Pokara",
  "Drums Of Heaven (Marinated chicken wings and drums cooked to perfection)",
  "Papdams",
  "Masala Chips",
  "Plain Chips",
  "Soup (Lentils & Chicken)",
]

let salads = [
  "Green Salad (Cucumber, tomato, carrot, onion & lettuce)",
  "Chicken Tikka Salad (Strips of chicken tikka pieces with cucumber, tomato, carrot & onion",
]

let chickenCurries = [
  "Butter Chicken (Marinated pieces of chicken breast cooked in creamy butter based tomato sauce)",
  "Chicken Korma (Chicken pieces cooked in a rich preparation of cashew nuts, cardamoms & coconut milk)",
  "Chicken Vindaloo (A Goa curry cooked with chicken pieces and a mixture  of spice)",
  "Chicken Kadai (A spice chicken thick gravy dish with a rich aroma given by the ginger & tomatos)",
  "Chicken Jalfrizi (Chicken cooked in an onion, tomato & pepper gravy)",
  "Chicken Palak (Chicken pieces slowly cooked in a rich spinach sauce)",
  "Chicken Tikka Masala (Grilled chicken cooked in tandoori & simmered in spice butter tomato sauce)",
  "Chicken Madras (Chicken pieces cooked with crushed roasted spice in a tomato & onion gravy tempered with curry leaves and coconut)",
  "Chicken Dopiaza (Chicken pieces cooked with onion pieces, a tomato and onion sauce is added at the second stage of cooking)"
]

let lambCurries = [
  "Lamb Roganjosh (Lamb curry infused with kashmari spice & cooked with ginger, garlic, onion & tomatoes)",
  "Lamb Korma (A popular dish of lamb pieces cooked in a rich preparation of cashew nuts, cardamoms & coconut milk)",
  "Lamb Palak (Lamb pieces slowly cooked in a rich spinach sauce)",
  "Lamb Dhal Gosht (Lamb pieces cooked along with yellow dhal, cumin seed and garlic)",
  "Lamb Vindaloo ( A Goa curry cooked with lamb pieces, potatoes & mixture of spice)",
  "Lamb Jalfrizi (Lamb cooked in a tomato, onion and green pepper gravy)",
  "Lamb Tikka Masala (Grilled lamb, cooked in the tandoori oven & simmered in spice, butter & tomato sauce)",
  "Lamb Kadai (A spice lamb thick gravy dish with a rich aroma given by the ginger & tomatoes)"
]

let seafoodCurries = [
  "Prawn Vindaloo (Goa curry cooked with prawns, potatoes & mixture of spice)",
  "Mixed Seafood Curry (A thick tomato based dish of prawns, fish, mussels & calamari, cooked with curry leaves, mustard, coconut & cumin seeds)",
  "Fish Curry (King Klip curry in an onion gravy with mustard seeds)",
  "Prawn Korma (A dish of prawns cooked in a rich preparation of cashew nuts, cardamoms & coconut milk)",
  "Prawn Madras (Prawns cooked with crushed roasted spice in an onion gravy, tempered with curry leaves)"
]

let vegtableCurries = [
  "Vegetable Curry (A mixed vegetable curry in tomato gravy with panner & spice)",
  "Vegetable Korma (A popular dish of vegetable cooked in a rich preparation of cashew nuts, cardamoms & coconut milk)",
  "Paneer Tikka Masala (Home made Indian cottage cheese cooked in a rich onion and tomato sauce)",
  "Panneer Korma (A popular dish of home made Insian cottage cheese cooked in a rich preparation cashew nuts, cardamoms & coconut milk)",
  "Paneer Makhani (Home made Indian cottage cheese simmered in a spice tomato & creamy butter sauce)",
  "Tadak Dhal (Yellow lentils tempered with garlic & cumin seeds)",
  "Dhal Makhani (Combination of black lentils & red beans with spice)",
  "Aloo Jeera (Cubed potatoes stir fried with cumin seeds & tomato)",
  "Chana Masach (Chickpeas cooked in a masala gravy with chopped ginger jawantry & cumin seeds)",
  "Aloo Gobi (Potatoes & cauliflower cooked in a masala gravy with cumin seeds)",
  "Palak Paneer (Home made Indian cottage cheese slowly cooked in a rich spinach gravy)"
]

let biryaniDishes = [
  "Chicken Biryani",
  "Lamb Biryani",
  "Fish Biryani",
  "Seafood Biryani",
  "Prawns Biryani",
  "Vegetable Biryani"
]

let tandooriDishes = [
  "Tandoori Chicken (Quarter & Half)",
  "Tandoori Lamb Chops",
  "Tandoori Platter (4 pieces of chicken tikka, 1 chicken tandoori quarter, 2 pieces lamb chops, 2 chicken samosas, rice, chips & salad)"
]

let nanRoti = [
  "Plain Nan",
  "Butter Nan",
  "Garlic Nan",
  "Cheese Nan",
  "Pshawari Nan",
  "Rogni Nan",
  "Aloo Partha",
  "Tandoori Roti",
  "Tandoori Roti – Butter"
]

let rice = [
  "Basmati Rice",
  "Egg Fried Rice",
  "Chicken Fried Rice",
  "Vegetable Fried Rice",
  "Jeera Rice",
  "Onion Fried Rice"
]

let indoChinese = [
  "Chicken Noodle",
  "Egg Noodle",
  "Vegetable Noodle",
  "Drums & Chips"
]

let bunnyChow = [
  "Lamb",
  "Chicken",
  "Beans",
  "Vegetable"
]

let accompaniments = [
  "Cucumber Ratia",
  "Mixed Ratia",
  "Sambals"
]

let deserts = [
  "Gulab Jamon",
  "Kheer (Rice Pudding)",
  "Falooda Ice Cream",
  "Vermicellai"
]

let beverages = [
  "Coca-Cola Products",
  "Water – Still/Sparkling",
  "Appletiser / Grapetiser",
  "Ice Tea (Peach Lemon)",
  "Mango Lassi",
  "Salt Lassi",
  "Sweet Lassi",
  "Bombay Crush",
  "Milkshake (Strawberry, Chocolate, Bubblegum, Banana, Lime)",
  "Rock Shandy / Malawi Shandy",
  "Cordials (Passion Fruit or Kola Tonic served with lemonade or soda)"
]

let tea = [
  "Masala Tea (Chai)",
  "Five Roses Tea",
  "Rooibos Tea",
  "Coffee"
]



const Page= () => (
  <Layout>
    <SEO title="Menu | Mount Everest Indian Restaurant" keywords={["Mount Everest Indian Restaurant", "Menu"]} />
    <PageHeading PageHeading="Our Menu"></PageHeading>    
    <MenuStyles>
      <h2>STARTERS</h2>
      <ul>
        {starters.map((starter, i)=>{
          return <li>{starter}</li>
        })}
      </ul>

      <h2>SALADS</h2>
      <ul>
        {salads.map((salad, i)=>{
          return <li>{salad}</li>
        })}
      </ul>

      <h2>CHICKEN CURRIES <br/><small>(All dishes served with Basmati rice)</small></h2>
      <ul>
        {chickenCurries.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>LAMB CURRIES <br/><small>(All dishes served with Basmati rice)</small></h2>
      <ul>
        {lambCurries.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>SEAFOOD CURRIES <br/><small>(All dishes served with Basmati rice)</small></h2>
      <ul>
        {seafoodCurries.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>VEGETABLE CURRIES <br/><small>(All dishes served with Basmati rice)</small></h2>
      <ul>
        {vegtableCurries.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>BIRYANI DISHES <br/><small>(Marinated meat vegetable pieces cooked with fragment Basmati rice and blended with delicate spice served with ratia)</small></h2>
      <ul>
        {biryaniDishes.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>TANDOORI <br/><small>(All cooked in a clay oven tandoori. All items are marinated in yoghurt & spice. Served with chips or rice & salad)</small></h2>
      <ul>
        {tandooriDishes.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>NAN / ROTI </h2>
      <ul>
        {nanRoti.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>RICE </h2>
      <ul>
        {rice.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>INDO CHINESE</h2>
      <ul>
        {indoChinese.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>BUNNY CHOW</h2>
      <ul>
        {bunnyChow.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>ACCOMPANIMENTS</h2>
      <ul>
        {accompaniments.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>DESSERTS</h2>
      <ul>
        {deserts.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>BEVERAGES</h2>
      <ul>
        {beverages.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>

      <h2>TEA</h2>
      <ul>
        {tea.map((item, i)=>{
          return <li>{item}</li>
        })}
      </ul>
      
    </MenuStyles> 
  
  </Layout>
)

export default Page
